<template>
  <ul class="phone-input">
    <li class="phone-input__input">
      <FlagsIndicatorSelectedComponent @changeIndicator="handleIndicator" />
    </li>
    <li class="phone-input__dropdown">
      <div class="input-container">
        <span class="prefix-value">{{ prefix }}</span>
        <el-input
          :label="$t('stopsInfo.createStopInfo.phone')"
          :class="emptyFields['phone'] === true ? 'missing-field' : ''"
          :floatLabel="true"
          :requiredField="true"
          v-model="contactPhone"
          placeholder="Phone Number"
          class="custom-input"
        />
      </div>
    </li>
  </ul>
</template>
<script>
import FormatPhoneNumberWithoutPrefix from "@/Extend/FormatPhoneNumberWithoutPrefix";
import FlagsIndicatorSelectedComponent from "./FlagsIndicatorSelectedComponent.vue";

export default {
  name: "InputPhone",
  components: {
    FlagsIndicatorSelectedComponent,
  },
  props: {
    emptyFields: Object,
    phoneValue: String,
  },
  mixins: [FormatPhoneNumberWithoutPrefix],
  data() {
    return {
      selectedFlag: "",
      contactPhone: "",
      prefix: "+1",
    };
  },
  watch: {
    contactPhone: {
      handler() {
        if (this.contactPhone !== "") {
          this.FormatterPhoneNumber();
        }
      },
    },
    selectedFlag() {
      this.$emit("changeFlag", this.selectedFlag);
      this.updatePhonePrefix();
    },
  },
  created() {
    if (this.phoneValue) {
      this.contactPhone = this.phoneValue;
    }
  },
  methods: {
    FormatterPhoneNumber() {
      const formattedPhoneNumber = this.FormatPhoneNumberWithoutPrefix(this.contactPhone);
      if (formattedPhoneNumber) {
        this.contactPhone = formattedPhoneNumber;
        this.$emit("changePhone", this.contactPhone);
      }
    },
    handleIndicator(newIndicator) {
      this.selectedFlag = newIndicator;
    },
    updatePhonePrefix() {
      this.addPrefixInThePhoneInput();
    },
    addPrefixInThePhoneInput() {
      this.prefix = "";
      if (this.selectedFlag === "MEX") {
        this.prefix = "+52";
      } else if (this.selectedFlag === "CAN" || this.selectedFlag === "USA") {
        this.prefix = "+1";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/components/RCComponents.scss";

.phone-input {
  @include remove-custom-component-styles;
  display: flex;
  flex-basis: 45%;
  list-style-type: none;
  align-items: baseline;
  &__input {
    &:not(:first-child) {
      .rc-input-container,
      .rc-input-field {
        @include hide-input-number-arrows;
        border-radius: 0px;
        border-left: none;
      }
    }
    .rc-input-container:nth-of-type(1),
    .rc-input-field:nth-of-type(1) {
      @include hide-input-number-arrows;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-right: none;
    }
    width: 38%;
  }

  &__dropdown {
    width: 100% !important;
    .rc-select-container {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    width: 25%;
  }
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.prefix-value {
  position: absolute;
  left: 10px;
  z-index: 1;
  font-size: 13px;
  pointer-events: none;
  color: #999;
}

.el-input__inner {
  padding-left: 40px;
}

::v-deep {
  .missing-field .el-input__inner {
    background-color: $missing-fields;
  }
  .custom-input .el-input__inner {
    padding-left: 4ch; /* Adds a padding-left of 3 characters */
  }
  .el-input__prefix {
    margin: 7px 0px 10px 0px;
  }
}
</style>
